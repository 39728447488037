<template>
  <div>
    <p
      v-if="label"
      class="text-xs leading-base-sm mb-x1 text-font-primary font-medium"
    >
      {{ label }}
      <span v-if="requiredSymbol" class="text-error font-bold">*</span>
    </p>
    <MazPhoneNumberInput
      :id="id"
      :disabled="disabled"
      v-model="phoneNumber"
      default-country-code="AU"
      v-model:country-code="country.name"
      show-code-on-list
      :invalid="invalid"
      @update:model-value="updateModelValue"
      @update="onUpdate"
      size="md"
      class="min-h-[42px] border-0 outline-none text-sm leading-base w-full"
      :class="{
        invalid: invalid,
      }"
    />
    <p v-if="invalid && invalidText" class="text-xs text-error">
      {{ invalidText }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import { MazPhoneResult } from "~/types/general";
import { parsePhoneNumber } from "libphonenumber-js";

const props = defineProps<{
  id?: string;
  modelValue: string;
  label?: string;
  labelFont?: string;
  invalid?: boolean;
  disabled?: boolean;
  invalidText?: string;
  requiredSymbol?: boolean;
  noInitial?: boolean;
}>();

const emit = defineEmits(["update"]);

const result = ref<MazPhoneResult>({} as MazPhoneResult);
const phoneNumber = ref();

const country = ref({
  name: "AU",
  code: null,
});

function parsePhone() {
  if (props.modelValue?.length && props.modelValue?.length > 8) {
    try {
      let phone = props.modelValue;

      if (!phone.startsWith("+")) {
        phone = "+" + phone;
      }

      const number = parsePhoneNumber(phone);
      if (number) {
        country.value.name = number.country ?? "AU";
        country.value.code = number.countryCallingCode;
        phoneNumber.value = props.modelValue;
      } else {
        country.value = {
          name: "AU",
          code: null,
        };
        phoneNumber.value = null;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  } else {
    country.value = {
      name: "AU",
      code: null,
    };
    phoneNumber.value = null;
  }
}

function onUpdate(event) {
  if (!event.nationalNumber) {
    return;
  }

  result.value = event;

  emit("update", {
    phone: result.value.formatInternational?.split(" ")?.join(""),
    isValid: result.value?.isValid,
  });
}

function updateModelValue(event) {
  if (event === undefined && !props.noInitial) {
    country.value = {
      name: "AU",
      code: null,
    };
    phoneNumber.value = null;
    emit("update", {
      phone: "",
      isValid: true,
    });
  }
}

watch(
  () => props.modelValue,
  (newVal) => {
    if (!newVal?.length) {
      country.value = {
        name: "AU",
        code: null,
      };
      phoneNumber.value = null;
    }
  }
);

onMounted(() => {
  parsePhone();
  nextTick();
});
</script>

<style lang="scss">
:root {
  --maz-border-color: #c6c6c6 !important;
  --border-radius: 0px !important;
}

.m-phone-number-input,
.m-phone-number-input[data-v-4877d2ea] {
  @apply min-h-[42px] max-h-[42px] h-[42px];
  border: none !important;
  box-sizing: border-box;
}
.m-input-label {
  display: none !important;
}
.m-input-wrapper,
.m-input-wrapper[data-v-c13c1d46] {
  border-width: 0px !important;
  border-radius: 5px !important;
  box-sizing: border-box;
}

.m-select.m-country-selector__select {
  border-radius: 5px 0px 0px 5px !important;
  width: 100px !important;
}

.m-country-selector__country-flag.--should-have-bottom-flag {
  bottom: 10px !important;
}

.m-input.--has-label .m-input-input {
  padding-top: 0px !important;
}

.m-phone-number-input__country-flag {
  left: 15px !important;
  top: 2px !important;
}
.m-input,
.m-input.--md,
.m-input.--md[data-v-c13c1d46] {
  @apply min-h-[42px] h-[42px] max-h-[42px];
}

.--is-disabled .m-phone-number-input__input {
  cursor: pointer !important;
}

.m-input-wrapper,
.m-input-wrapper[data-v-c13c1d46] {
  @apply min-h-[42px] border-[1px] py-2.5 text-sm;
}
.m-input {
  border: solid 1px #e4e4e7;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  background-color: #fff;
}
.m-country-selector > .m-select > .m-input,
.m-country-selector > .m-select > .m-input.--is-focused {
  border: solid 1px #e4e4e7;
  border-right: 0 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background-color: #fff;
}
.m-input-wrapper-input {
  width: 70px;
}

#country-selector-onboarding-basic-contact-mobile {
  width: 70px;
}
.m-select-list__search-input {
  border-radius: 0px !important;
}

.nocorrect {
  .m-input-wrapper,
  .m-input-wrapper[data-v-c13c1d46] {
    @apply border-error border;
  }
}
.m-select-chevron {
  @apply stroke-font-light max-w-[25px] max-h-[25px];
}
.m-select-chevron svg {
  @apply stroke-font-light stroke-border-color;
}

.m-select-list,
.m-select-list[data-v-20fe1c4f] {
  z-index: 10 !important;
  background-color: #fff !important;
}
.m-select-list-item {
  min-height: 20px !important;
}
.m-select-list-item span {
  padding: 15px 3px !important;
  margin-bottom: 15px !important;
  font-size: 12px !important;
  color: #242424 !important;
}
.m-select-list-item span:nth-child(2):before {
  content: "(";
  color: #242424 !important;
}
.m-select-list-item span:nth-child(2):after {
  content: ")";
  color: #242424 !important;
}
.m-phone-number-input__select .m-select-input .m-input-wrapper {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right: 0 !important;
}

.m-phone-number-input.invalid {
  border-radius: 5px !important;
  .m-input,
  .m-input.--is-focused {
    border-color: red !important;
    border-left: 0 !important;
  }
  .m-country-selector > .m-select > .m-input,
  .m-country-selector > .m-select > .m-input.--is-focused {
    border-color: red !important;
    border-left: solid 1px red !important;
    border-right: solid 1px #e4e4e7 !important;
  }
}

.m-phone-input {
  min-width: auto !important;
}

.m-phone-number-input__select {
  max-width: 100px !important;
}
.m-phone-number-input__input.--border-radius .m-input-wrapper {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.m-input {
  flex-grow: 1;
}

.m-phone-number-input.--responsive .m-phone-input {
  margin-top: 0 !important;
  flex: 1 1 auto !important;
}
.m-phone-number-input.--col,
.m-phone-number-input.--responsive {
  flex-direction: row !important;
}
.m-phone-number-input.--responsive .m-country-selector {
  min-width: auto !important;
}
</style>
